import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { setConfig } from '../utils/helpers'

let configured = false

function ConfigureApp() {
    const navigate = useNavigate()

    useEffect(() => {
        if (!configured) {
            configured = true
            setConfig()
            navigate("/", { replace: true })
        }
        return () => { }
    }, [navigate])

    return (
        <div>Loading...</div>
    )
}

export default ConfigureApp
