import logo from "../assets/images/logo.png";
import { getConfig } from '../utils/helpers';

const instanceConfig = getConfig();

export default function Header() {
    const logoURL = instanceConfig.logo || logo;
    return (
        <header className="App-header">
            <img src={logoURL} className="App-logo" alt="logo" />
        </header>
    )
}
