import { Link } from "react-router-dom";

function ErrorComponent() {
    return (
        <>
            <main className="transaction-page error">
                <article>
                    No User Found.
                </article>
            </main>
            <Link to="/" className="start-over-link">Start Over</Link>
        </>
    )
}

export default ErrorComponent