
import "../assets/styles/App.css";
import Header from "../components/Header";
import LookupStakeholder from "./LookupStakeholder";
import ConfirmTransaction from "./ConfirmTransaction";
import GenerateTransaction from "./GenerateTransaction";
import { Routes, Route } from 'react-router-dom';
import IntentProvider from '../utils/IntentProvider';
import ConfigureApp from "../components/ConfigureApp";
import styled from 'styled-components'
import { getConfig } from '../utils/helpers';

const instanceConfig = getConfig();

const Root = styled.div.attrs(props => ({
  className: "root",
}))`
  &::after {
    background: linear-gradient(90deg, #ca1145 24%, transparent 24%, transparent 75%, ${instanceConfig.color || "#7cb974"} 75%);
  }
`

function App() {

  return (
    <Root>
      <div className="App">
        <IntentProvider>
          <Header />
          <Routes>
            <Route path="/config" element={<ConfigureApp />} />
            <Route path="/" element={<LookupStakeholder />} />
            <Route path="/details" element={<GenerateTransaction />} />
            <Route path="/card" element={<ConfirmTransaction />} />
          </Routes>
        </IntentProvider>
      </div>
    </Root>

  );
}

export default App;
