import ErrorComponent from "../components/ErrorComponent";
import CheckPaymentStatus from "../components/CheckPaymentStatus";
import MemberIntentProfile from "../components/MemberIntentProfile";
import { getUserFromStorage } from "../api/user";
import { IntentContext } from "../utils/IntentProvider";
import { useContext, useEffect, useState } from "react";
import { setPaymentIntent } from "../api/intent";

function ConfirmTransaction() {
    const user = getUserFromStorage()
    const { intent, mergeIntent } = useContext(IntentContext)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let mounted = true;

        async function setTheIntent() {
            setLoading(true)
            const response = await setPaymentIntent(intent)
            if (response) {
                mergeIntent(response)
            }
            setLoading(false)
        }

        if (!loading && mounted) setTheIntent()

        return () => mounted = false;
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return user ? (
        <>
            <main className="transaction-page">
                <MemberIntentProfile />
                <CheckPaymentStatus />
            </main>
        </>
    ) : (<ErrorComponent />)
}

export default ConfirmTransaction
