import React, { useState } from "react";

export const IntentContext = React.createContext();

function IntentProvider(props) {
    return (
        <IntentContext.Provider value={useIntent()}>
            {props.children}
        </IntentContext.Provider>
    )
}

const defaultIntent = {
        'id': 0,
        'amount': 0,
    }

function useIntent() {
    const [intent, setIntent] = useState(defaultIntent)

    return {

        intent,

        resetIntent() {
            setIntent(defaultIntent);
        },

        mergeIntent(extra) {
            setIntent({...intent, ...extra})
        },

        setIntent

    }
}

export default IntentProvider
