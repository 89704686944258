import { useContext } from 'react';
import { IntentContext } from "../utils/IntentProvider"

function MemberIntentProfile(props) {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const {intent} = useContext(IntentContext);

    return (
        <dl>
            <dt>Individual:</dt>
            <dd>{user.name}<br />#{user.memberID}</dd>

            {intent.amount ? <><dt>Amount:</dt><dd>${intent.amount}</dd></> : ''}

            {intent.action_taken_id ? <>
                <dt>Transaction ID:</dt>
                <dd>{intent.action_taken_id}</dd>
            </> : ''}
        </dl>
    )
}

export default MemberIntentProfile
