import { getConfig } from "../utils/helpers";

const instanceConfig = getConfig();

async function cancelPaymentIntent(intent) {
    const response = await fetch(`${instanceConfig.apiUrl}/wp-json/wsd-ipp/v1/cancel-payment/${instanceConfig.action}/${instanceConfig.instanceID}`, {
        method: "POST",
        body: JSON.stringify(intent),
        headers: {
            "Authorization": instanceConfig.auth,
            "Content-type": "application/json; charset=UTF-8"
        }
    })

    return response.json()
}

async function setPaymentIntent(intent) {
    const response = await fetch(`${instanceConfig.apiUrl}/wp-json/wsd-ipp/v1/initiate-payment/${instanceConfig.action}/${instanceConfig.instanceID}`, {
        method: "POST",
        body: JSON.stringify(intent),
        headers: {
            "Authorization": instanceConfig.auth,
            "Content-type": "application/json; charset=UTF-8"
        }
    })

    return await response.json()
}

async function checkPaymentStatus() {
    return await fetch(`${instanceConfig.apiUrl}/wp-json/wsd-ipp/v1/payment-status/${instanceConfig.action}/${instanceConfig.instanceID}`, {
        method: "POST",
        headers: {
            "Authorization": instanceConfig.auth,
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(response => response.json())
        .catch(reason => { return { status: 'failed', message: reason } })
}

async function finalizePaymentIntent(intent) {
    return await fetch(`${instanceConfig.apiUrl}/wp-json/wsd-ipp/v1/finalize-payment/${instanceConfig.action}/${instanceConfig.instanceID}`, {
        method: "POST",
        body: JSON.stringify(intent),
        headers: {
            "Authorization": instanceConfig.auth,
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(response => response.json())
}

export { cancelPaymentIntent, setPaymentIntent, checkPaymentStatus, finalizePaymentIntent }
