import { moneyFormat } from "../utils/helpers";

function ContributionTotals(props) {
    return (
        <article className="ContributionTotals">
            <dl>
                <dt>{new Date().getFullYear()} Contribution Total</dt>
                <dd>{moneyFormat(props.contributions.yearTotal)}</dd>
                <dt>Lifetime Contribution Total</dt>
                <dd>{moneyFormat(props.contributions.lifetime)}</dd>
            </dl>
        </article>
    )
}

export default ContributionTotals