import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getUserFromStorage } from '../api/user';
import { moneyFormat } from "../utils/helpers";
import { IntentContext } from '../utils/IntentProvider';
import styled from 'styled-components'
import { getConfig } from '../utils/helpers';

const instanceConfig = getConfig();

const StyledContributionButton = styled(Link).attrs(props => ({
    className: "contribution-btn",
}))`
    background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
    cursor:pointer;
    &.disabled {
        background:gray;
        pointer-events:none;
        cursor:not-allowed;
    }
`

function ContributionButton(props) {
    const user = getUserFromStorage();

    const { setIntent } = useContext(IntentContext);

    const updateIntent = () => setIntent({
        id: parseInt(user.id || 0),
        amount: parseFloat(props.amount || 0)
    });

    const disabled = instanceConfig.cap && user.cap < props.amount;

    return (
        <StyledContributionButton to={"/card"} onClick={(e) => disabled ? e.preventDefault() : updateIntent()} className={disabled ? 'disabled' : ''}>{moneyFormat(props.amount)}</StyledContributionButton>
    )
}

export default ContributionButton
