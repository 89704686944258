import { getConfig, getAPIGetHeader } from "../utils/helpers";

const instanceConfig = getConfig();

function deleteUserLookupVariables() {
    sessionStorage.removeItem('memberID')
    sessionStorage.removeItem('emailAddress')
    sessionStorage.removeItem('lastName')
    sessionStorage.removeItem('phone')
}

async function getUserFromAPI() {
    const id = sessionStorage.getItem('memberID')
    const email = sessionStorage.getItem('emailAddress')
    const name = sessionStorage.getItem('lastName')
    const phone = sessionStorage.getItem('phone')

    const url = `${instanceConfig.apiUrl}/wp-json/wsd-ipp/v1/lookup?action=${instanceConfig.action}&instance=${instanceConfig.instanceID}&`;
    const query = new URLSearchParams();

    if (id) query.append("external_id", id);
    if (email) query.append("email", email);
    if (name) query.append("lName", name);
    if (phone) query.append("phone", phone);

    deleteUserLookupVariables();

    return await fetch(url + query.toString(), getAPIGetHeader())
        .then(response => response.json())
        .catch(reason => { return { status: 'failed', message: reason } })
}

function getUserFromStorage() {
    const user = sessionStorage.getItem('user');
    return user ? JSON.parse(user) : false;
}

function setStoredUser(user) {
    sessionStorage.setItem('user', JSON.stringify(user));
}

async function getUser() {
    const storedUser = getUserFromStorage();

    if (storedUser) return Promise.resolve(storedUser);

    const user = await getUserFromAPI();

    if (user.error) return user;

    setStoredUser(user)

    return user
}

export { getUser, getUserFromAPI, getUserFromStorage, setStoredUser }
