import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserFromStorage } from '../api/user';
// import { moneyFormat } from "../utils/helpers";
import { IntentContext } from '../utils/IntentProvider';
import styled from 'styled-components'
import { getConfig } from '../utils/helpers';

const instanceConfig = getConfig();

const StyledContributionButton = styled(Link).attrs(props => ({
    className: "contribution-btn contribution-other-btn",
}))`
    background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
    cursor:pointer;
    &.disabled {
        background:gray;
        pointer-events:none;
        cursor:not-allowed;
    }
`

const OtherBox = styled.div`
    grid-column: 1 / span 2;
    gap:15px;
    display:grid;
    align-items:start;
    justify-content:center;
    align-items:center;

    .OtherHeading {
        display:grid;
        gap:20px;
        text-align:center;
    }
`

const OtherInput = styled.input.attrs(props => ({
    placeholder: "0.00",
    type: "number",
    step: "any",
}))`
    border-width: 0 0 2px;
    font-size: 30px;
    color: #555;
    font-weight: bold;
    padding: 10px 0 10px 30px;
    border-radius: 0;
    margin-bottom:40px;
    width:150px;

    &:focus-visible, &:focus {
        outline:none;
        border-width: 0 0 2px;
        border-color:mediumseagreen;
    }
`

const OtherInputContainer = styled.div`
    position:relative;
    display: flex;
    align-items: baseline;
    justify-content: center;

    &:before {
        content:"$";
        font-size:30px;
        color:mediumseagreen;
        margin-right: -15px;
        position:relative;
        z-index:1;
    }
`

const StyledCapMessage = styled.p`
    width:378px;
    max-width:100%;
    color:darkred;
    font-size:14px;
    line-height:1.5em;
`

function ContributionOtherButton() {
    const [open, setOpen] = useState(false)
    const [disable, setDisable] = useState(false);
    const [amount, setAmount] = useState('')
    const user = getUserFromStorage()

    const { setIntent } = useContext(IntentContext);

    const updateIntent = () => setIntent({
        id: parseInt(user.id || 0),
        amount: parseFloat(amount || 0)
    })

    const handleOther = e => {
        e.preventDefault();
        setOpen(true)
    }
    const updateAmount = e => {
        if (instanceConfig.cap) setDisable(e.target.value > user.cap)
        setAmount(e.target.value)
    }

    return (
        <>
            {
                open ?
                    <OtherBox>
                        <div className="OtherHeading">
                            <h2>Other Amount</h2>
                            <p>Please enter the amount you would like to contribute.</p>
                            {instanceConfig.cap && (amount > user.cap) ? <StyledCapMessage>{instanceConfig.capMsg}</StyledCapMessage> : ''}
                        </div>
                        <OtherInputContainer>
                            <OtherInput onChange={e => updateAmount(e)} value={amount} max={instanceConfig.cap ? user.cap : ''} />
                        </OtherInputContainer>
                        <StyledContributionButton to={"/card"} onClick={(e) => disable ? e.preventDefault() : updateIntent()} className={disable ? 'disabled' : ''}>Next Step</StyledContributionButton>
                    </OtherBox>
                    : <StyledContributionButton onClick={e => handleOther(e)}>Other</StyledContributionButton>
            }
        </>
    )
}

export default ContributionOtherButton
