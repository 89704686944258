const moneyFormat = amount => new Intl.NumberFormat("en-US", { style: "currency", "currency": "USD" }).format(amount);

const getFreshConfig = () => {
    let params = new URLSearchParams(document.location.search)
    const getColor = params.get('color') || '#000'
    const getInstanceID = params.get('id') || 0
    const getAction = parseInt(params.get('action') || 0)
    const getAuth = `Basic ${params.get('auth') || ''}`
    const getAmounts = params.get('amounts') ? params.get('amounts').split(',') : [5.00, 10.00, 25.00, 50.00, 100.00, 250.00]
    const getApiUrl = params.get('url') || '';
    const getLogo = params.get('logo') || '';
    const getCap = params.get('cap') || false;
    const getCapMsg = params.get('capMsg') || '';
    const getEnablePhoneLookup = params.get('enablePhoneLookup') || false;
    return {
        amounts: getAmounts,
        auth: getAuth,
        action: getAction,
        instanceID: getInstanceID,
        color: getColor,
        apiUrl: getApiUrl,
        logo: getLogo,
        cap: getCap,
        capMsg: getCapMsg,
        enablePhoneLookup: getEnablePhoneLookup
    }
}

const setConfig = async () => {
    const defaultConfig = getFreshConfig()
    sessionStorage.setItem('config', JSON.stringify(defaultConfig))
}

const getConfig = () => {
    const localConfig = sessionStorage.getItem('config');
    return localConfig ? JSON.parse(localConfig) : getFreshConfig();
}

const getAPIGetHeader = () => ({
    method: "GET",
    headers: {
        "Authorization": getConfig().auth,
        "Content-type": "application/json; charset=UTF-8"
    }
})

export { moneyFormat, getConfig, setConfig, getAPIGetHeader }
