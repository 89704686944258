import { useState, useContext } from "react"
import { cancelPaymentIntent, checkPaymentStatus, finalizePaymentIntent } from "../api/intent"
import { Link } from "react-router-dom"
import { IntentContext } from "../utils/IntentProvider"
import useInterval from "../hooks/useInterval"

const STATUS = {
  WAITING_FOR_READER: 0,
  WAITING_FOR_CARD: 1,
  FAILURE: 2,
  READ_SUCCESS: 3,
  CHARGED: 4
}

function CheckPaymentStatus() {
  const [statusNumber, setStatusNumber] = useState(STATUS.WAITING_FOR_READER)
  const [intervalLength, setIntervalLength] = useState(250)
  const {intent} = useContext(IntentContext)
  const [cardError, setCardError] = useState('')

  let msg = <>
    <p>Preparing payment</p>
    <p>Please wait while the card reader initializes...</p>
  </>

  const finalizePayment = async () => {
    const finalize = await finalizePaymentIntent(intent)
    if (finalize.success) setStatusNumber(STATUS.CHARGED)
  }

  useInterval(async () => {
    if (statusNumber === STATUS.WAITING_FOR_READER) {
      if (!intent.payment_id) {
        return
      }
      setIntervalLength(3500)
      setStatusNumber(STATUS.WAITING_FOR_CARD)
    }
    const response = await checkPaymentStatus()
    switch (response.status) {
      case 'succeeded':
        setStatusNumber(STATUS.READ_SUCCESS)
        setIntervalLength(null)
        finalizePayment()
        break
      case 'failed':
        setStatusNumber(STATUS.FAILURE)
        setCardError(response.message)
        setIntervalLength(null)
        break
      case 'in_progress':
      default:
        if (statusNumber !== STATUS.WAITING_FOR_CARD) setStatusNumber(STATUS.WAITING_FOR_CARD)
        break
    }
  }, intervalLength)

  if (statusNumber === STATUS.WAITING_FOR_CARD) msg = <>
    <p>Please use card reader to complete transaction</p>
    <Link to="/details" onClick={() => cancelPaymentIntent(intent)}>Go Back to Update Amount</Link>
  </>

  if (statusNumber === STATUS.FAILURE) msg = <>
    <p>Card Failure!</p>
    <p>The reader encountered the following card error:</p>
    <p>{cardError}</p>
    <Link to="/" className="begin-new-link">Begin a New Transaction</Link>
  </>

  if (statusNumber === STATUS.READ_SUCCESS) msg = <>
    <p>Transaction is Processing</p>
    <p>Do Not Close this Window</p>
  </>

  if (statusNumber === STATUS.CHARGED) msg = <>
    <p>Transaction is Complete</p>
    <p>Thank You Email Sent</p>
    <Link to="/" className="begin-new-link">Begin a New Transaction</Link>
  </>

  return (
    <article className="contributionStatus">{msg}</article>
  )
}

export default CheckPaymentStatus
