import { moneyFormat } from "../utils/helpers";

function RecentContributionsSegment(props) {
    return (
        <>
            <dt>{props.contribution.date}</dt>
            <dd>{moneyFormat(props.contribution.amount)}</dd>
        </>
    )
}

function RecentContributions(props) {
    return (
        <article className="recentContributions">
            <h3>Recent Contributions</h3>
            <dl>
                {props.contributions.map((contribution, index) => <RecentContributionsSegment key={index} contribution={contribution} />)}
            </dl>
        </article>
    )
}

export default RecentContributions