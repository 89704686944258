import styled from 'styled-components'
import { getConfig } from '../utils/helpers'

const instanceConfig = getConfig()

const StyledSubmit = styled.button.attrs(props => ({
    type: 'submit',
    className: "submit-btn",
}))`
    background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
    cursor:pointer;
    &.disabled {
        background:gray;
        pointer-events:none;
        cursor:not-allowed;
    }
`

const StyledButton = styled.button.attrs(props => ({
    className: "submit-btn",
}))`
    background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
    cursor:pointer;
    &.disabled {
        background:gray;
        pointer-events:none;
        cursor:not-allowed;
    }
`

export { StyledButton, StyledSubmit }